<template>
  <section class="section is-main-section">
    <div class="box">
      <div class="columns">
        <div class="column">
          <SelectLocation v-model="search.locationId" />
        </div>
        <div class="column">
          <b-field horizontal label="Title">
            <b-input v-model="search.title" />
          </b-field>
        </div>
      </div>
    </div>

    <div class="columns">
      <div class="column">
        <ContextTableCard
          title="Risks"
          icon="shield-alert"
          :header-icons="headerIcons"
          :tableData="tableData"
          :sortField="search.sortField"
          :sortOrder="search.sortOrder"
          :page="search.page"
          :total="total"
          @refreshData="debounceRefreshData"
          @page-change="pageChange"
          @sort="sort"
          v-model="selected"
          @headerIconClicked="headerIconClicked"
          @contextDefaultClicked="editRisk"
          :isLoading="isLoading"
          :rowClass="setRiskColourSelected"
        >
          <b-table-column label="Rating" field="risk" sortable v-slot="props">
            <div class="is-selected invertCellPadding" :class="setRiskColourSelected(props.row)">
              <b-icon :icon="icon(props.row.risk)" size="is-small" /> {{ riskName(props.row.risk) }}
            </div>
          </b-table-column>
          <b-table-column label="Location" field="location" sortable v-slot="props">
            {{ props.row.location }}
          </b-table-column>
          <b-table-column label="Title" field="title" sortable v-slot="props">
            {{ props.row.title }}
          </b-table-column>
          <b-table-column label="Category" field="category" sortable v-slot="props">
            {{ props.row.category }}
          </b-table-column>
          <b-table-column label="Role" field="role" sortable v-slot="props">
            {{ props.row.role }}
          </b-table-column>
          <b-table-column label="Review Date" field="reviewDate" sortable v-slot="props">
            {{ shortFormat(props.row.reviewDate) }}
          </b-table-column>
        </ContextTableCard>
      </div>
    </div>
  </section>
</template>

<script>
import SelectLocation from "@/components/SelectLocation.vue";
import ContextTableCard from "@/components/ContextTableCard.vue";
import ContextTableMixin from "@/mixins/contextTableMixin";
import IconsMixin from "@/mixins/iconsMixin.js";

import Risk from "@/enums/risk";
import Dates from "@/common/dates";
import { mapMutations } from "vuex";
import PermissionsEnum from "@/enums/permissions";
import AssessmentType from "@/enums/assessmentType";

export default {
  components: { SelectLocation, ContextTableCard },
  mixins: [ContextTableMixin],
  data() {
    const headerIcons = [IconsMixin.headerIconRefresh()];
    if (this.$store.getters.hasPermission(PermissionsEnum.RiskAdd)) {
      headerIcons.unshift(IconsMixin.headerIconAdd("Risk"));
    }

    return {
      url: "risks",
      entity: "Risks",
      search: {
        locationId: this.$store.state.risks.search.locationId,
        title: this.$store.state.risks.search.title,
        page: 1,
        sortField: "risk",
        sortOrder: "desc"
      },
      headerIcons: headerIcons
    };
  },
  watch: {
    "search.locationId": "debounceRefreshData",
    "search.name": {
      handler() {
        this.debounceRefreshData();
        this.setSearch(this.search);
      }
    }
  },
  methods: {
    ...mapMutations("risks", ["setSearch"]),
    riskName(risk) {
      return Risk.LookupShort[risk];
    },
    shortFormat(date) {
      return Dates.shortFormat(date);
    },
    setRiskColourSelected(row) {
      return AssessmentType.assessmentColor(AssessmentType.Enum.Generic, row.risk, true);
    },
    icon(risk) {
      return AssessmentType.riskIcon(AssessmentType.RiskTypeEnum.Standard3, risk);
    },
    async headerIconClicked(iconName) {
      switch (iconName) {
        case "add":
          this.$router.push({ name: "risk.new" });
          break;
      }
    },
    editRisk() {
      if (this.selected.riskId) this.$router.push({ name: "risk.edit", params: { id: this.selected.riskId } });
    }
  }
};
</script>
